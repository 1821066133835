.cardDetails {
  height: 170px;
  width: 300px;
  background-color: white;
  padding: 10px;
  position: absolute !important;
  top: 30px;
  left: 30px;
}
.heading-sub {
  margin-top: -13px;
}
.cardDetailsInfo {
  display: flex;
  justify-content: space-around;
}
.cardDetailsFont {
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  color: #05284e;
}
.cardDetailsNumber {
  color: #0c0c0c;
  font-weight: bold;
}
