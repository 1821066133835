
.dateSearch {
  padding: 0rem 24px 1rem 24px;   
  position: relative;
  top: 21px;
}

.baxterCapitalize ::placeholder {
  text-transform: capitalize !important;
}

// .invoicing-table-box{
//   max-height: 400px;
//   overflow: scroll;
// }
.IndexDataTableNeoParentScroll {
  display: block;
  width: 100%;
  overflow: auto;

  .scroollDataTableNeoScroll {
    white-space: initial;
    width: 100%;
    display: table;
    overflow: auto;
    flex-wrap: nowrap !important;

    .CardDateTableScroll1,
    .CardDateTableScroll {
      width: max-content;

      .IndexDatatableNeoRowScroll {
        display: flex;
        white-space: wrap;
        overflow-y: visible;
        flex-wrap: nowrap !important;
        width: 100%;
        max-width: -webkit-fill-available;

        .IndexDatatableNeoCol {
          text-align: left;
          // padding-left: 100px;
        }
      }
    }

    .CardDateTableScroll {
      min-width: 100%;
    }

    .CardDateTableScroll1 {
      min-width: 100%;
    }
  }
}

.IndexDatatableNeoRow {
  display: flex;
  white-space: initial;
  flex-wrap: nowrap !important;
}

.IndexDatatableNeoCol {
  text-align: left;
  // padding-left: 50px;
}

.IndexDatatableNeoCol1 {
  // text-align: left;
  // padding-left: 43px;
  // height: 50px;

  .rs-picker {
    border: 1px solid #e6e6e6;
  }

  .rs-picker-toggle-value {
    color: #6e6e6e !important;
    font-size: 13px;
    padding-top: 2px;
    height: 26px;
    left: 23px;
    margin-left: 15px;
  }

  .rs-picker {
    :placeholder {
      position: relative;
      left: 50px !important;
    }
  }
}

.inputIndex {
  // text-align: left;
  // padding-left: 43px;
  height: 50px;

  // margin-left: -31px !important;
  .rs-picker {
    border: 1px solid #e6e6e6;
  }

  .rs-picker-toggle-value {
    color: #6e6e6e !important;
    font-size: 13px;
    padding-top: 2px;
    height: 26px;
    left: 23px;
    margin-left: 15px;
  }

  .rs-picker {
    :placeholder {
      position: relative;
      left: 50px !important;
    }
  }
}

.resetSearchBar {
  width: 78px;
  float: right;
  height: 25px;
  line-height: 0px;
  background-color: #0ab38c;
  color: #ffffff;
  font-size: 12px;
  margin-right: 15px;
  border: none;
}

.resetSearchBar:hover {
  background-color: #0bb17f !important;
  // color: inherit !important;
}

.myResearSearchBar {
  width: 80px;
  float: right;
  height: 25px;
  line-height: 0px;
  background-color: #0ab38c;
  font-size: 12px;
  margin-right: 15px;
  margin-top: 0px;
  border: none;
}

.searchToggleDiv {
  height: 180px !important;
  display: flex !important;
  align-items: center !important;
  margin-top: -8px !important;
  padding-bottom: 35px;
}

.orderList .searchToggleDiv {
  height: 100px !important;
  display: flex !important;
  align-items: center !important;
  margin-top: -10px !important;
}

.productList .searchToggleDiv {
  height: 150px !important;
  display: flex !important;
  align-items: center !important;
  margin-top: -10px !important;
}

.dispatchList .searchToggleDiv {
  height: 66px !important;
  display: flex !important;
  align-items: center !important;
  margin-top: -10px !important;
}

.roleIndex .searchToggleDiv {
  height: 66px !important;
  display: flex !important;
  align-items: center !important;
  margin-top: -9px !important;
}

.invoiceList .searchToggleDiv {
  height: 202px !important;
  display: flex !important;
  align-items: center !important;
  margin-top: -9px !important;
}

.tripList .searchToggleDiv {
  height: 215px !important;
  display: flex !important;
  align-items: center !important;
  margin-top: 0px !important;
  margin-bottom: 5px !important;
}

.orderList .RenderInput {
  width: 95%;

  .select2-container input {
    border: 1px solid #e6e6e6;
  }
}

.RenderInput {
  width: 95%;
  padding: 0rem 24px 1rem 24px;    

  .select2-container input {
    // border: 1px solid #e6e6e6;
    border-radius: 4px;
    border: 0.5px solid #b9b9b9;
    background: #fff;
  }
}

.RenderInput:hover .form-control {
  /* Add your hover styles here */
  border-color: #b9b9b9;
  /* Change border color on hover */
  box-shadow: 0 0 3px #b9b9b9;
  /* Add box shadow on hover */
}

@media (max-width: 640px) {
  .RenderInput {
    width: 98%;
  }

  .IndexDataTableNeoParent {
    display: block;
    width: 100%;
    overflow: auto;

    .scroollDataTableNeo {
      // display: contents;
      white-space: initial;
      // overflow-y: hidden;

      width: 100%;
      display: table;
      overflow: auto;
      flex-wrap: nowrap !important;
      // padding: 0px 15px;

      .CardDateTable {
        width: max-content;
        min-width: 200%;

        .IndexDatatableNeoRowScroll,
        .IndexDatatableNeoRow {
          display: flex;
          white-space: nowrap;
          overflow-y: visible;
          flex-wrap: nowrap !important;
          width: 100%;
          max-width: -webkit-fill-available;

          .IndexDatatableNeoCol {
            text-align: left;
            // padding-left: 100px;
          }
        }
      }
    }
  }
}

@media (max-width: 1300px) {
  .IndexDataTableNeoParent {
    display: block;
    width: 100%;
    overflow: auto;

    .scroollDataTableNeo {
      // display: contents;
      white-space: initial;
      // overflow-y: hidden;

      width: 100%;
      display: table;
      overflow: auto;
      flex-wrap: wrap !important;
      // padding: 0px 15px;

      .CardDateTable {
        width: max-content;
        min-width: 100%;

        .IndexDatatableNeoRowScroll,
        .IndexDatatableNeoRow {
          display: flex;
          white-space: wrap;
          overflow-y: visible;
          flex-wrap: wrap !important;
          width: 100%;
          max-width: -webkit-fill-available;

          .IndexDatatableNeoCol {
            text-align: left;
            // padding-left: 100px;
          }
        }
      }
    }
  }
}

@media (max-width: 1300px) {
  .paddingsizewise {
    padding-right: 16px !important;
  }
}


// TOOL-TIP css----------------------------------------------------------------

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  visibility: hidden;
  background-color: black;
  color: white;
  text-align: center;
  padding: 5px 8px;
  border-radius: 4px;
  position: absolute;
  bottom: 100%;
  left: 50%;
  bottom: -38px;
  transform: translateX(-50%);
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

// TOOL-TIP css----------------------------------------------------------------END

.search-filter2 {
    width: 32px;
  height: 32px;
  padding: 14px !important;
  margin: 0;
  font-size: 12px;
  box-sizing: border-box;
  background: white;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.filter-bdr {
  border-radius: 4px;
  border: 1px solid #D9D9D9;

  background: #FFF;
}

.filter-img {
  width: 14px;
  height: 14px;
}

// lib css force change----------------
.select2-selection__indicators {
  margin: auto;
  transition: all 0.6s ease-in-out;
}

.select2-selection__dropdown-indicator {
  margin-top: 0 !important;
}

.excil-btn {
  border-radius: 4px;
  border: 1px solid #2800FC;

  background: #F1F5F7;
}

.search-padding {
  z-index: 50;
  position: sticky;
  top: 0;
  left: 0;
  width: 95vw;
  padding: 12px 24px 12px 16px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1.6px;
}
.search-input-view2 {
  height: 32px;
  padding: 4px 24px;
  // height: auto;
  padding-left: 45px;
  margin: 0;
  font-size: 12px;
  box-sizing: border-box;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
}

// table css------------------
.phone-icon {
  // cursor: pointer;
  font-size: 12px;
  margin-left: 5px;
  position: relative;
}

.phone-tooltip {
  display: none;
  position: absolute;
  top: 100%;
  left: 62px;
  transform: translateX(-50%);
  padding: 6px 12px;
  background: #3e4754;
  color: white;
  border-radius: 4px;
  font-size:12px;
  white-space: nowrap;
  z-index: 88;
  transition: opacity 0.2s ease-in-out;
  gap: 8px;
  align-items: center;
}

.phone-icon:hover .phone-tooltip {
  display: inline-flex;
  gap: 8px;
}

.icon-container:hover .showroute {
  display: inline-flex;
}

.route-container {
  position: relative;
}

.icon-container {

  display: flex;
  height: 16px;
  padding: 6px 4.5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1.2px solid #2800FC;
  background: rgba(40, 0, 252, 0.10);
}
.total-labels{
  color: #2800FC !important;
}

.tooltip-lable {
  color: #2800FC;
  text-align: center;
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.showroute {
  flex-direction: column;
  display: none;
  position: absolute;
  top: 100%;
  left: 200px;
  transform: translateX(-50%);
  padding: 12px 16px;
  margin-top: 8px;
  background: #3e4754;
  color: white;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 88;
  transition: opacity 0.2s ease-in-out;
  gap: 0px;
  align-items: self-start;
}

.tooltip-item {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  padding: 5px 0;
  position: relative;
}

/* Circle dot */
.timeline-dot {
  width: 8px;
  height: 8px;
  background-color: #ffffff;
  /* Adjust color as needed */
  border-radius: 50%;
  position: relative;
}
.somecalsses2{
  display: flex;
  width: 85%;
}

/* Vertical bar */
.tooltip-item::before {
  content: "";
  position: absolute;
  width: 2px;
  background-color: #ffffff;
  /* Same as dot color */
  top: -6px;
  bottom: 19px;
  left: 2.8px;
  /* Align with the dot */
}

/* Hide bar for the first element */
.tooltip-item:first-child::before {
  display: none;
}

.someclass {
  display: inline-block;
  max-width: 80%;
  /* Adjust as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btnfsize {
  font-size: 10px !important;
}

// table css------------------END

.table-card {
  overflow: visible;
  // overflow-x: scroll;
  // margin-top: 68px !important;

}

.table {
  display: flex;
  flex-direction: column;
  // width: fit-content;
  width: 100%;
}

.table-container1 {
  width: 100%;
  overflow: visible;
  // z-index: 55;
}

.table-header,
.tableRow {
  display: flex;
  // width: 1390px;
  padding: 8px 16px !important;
  align-items: center;
  gap: 12px;
  background: #F1F5F7;
}

.tableRow {
  background: #FFF;
  min-width: 1200px;
}

.table-cell {
  color: #6D7885;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.table-cell-row {
  color: #3E4754;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.table-scroll {
  overflow-x: auto;
  width: 95vw;
  height: 86vh;
}
.searchBar-table{
  background-color: white;
}
@media (min-height:750px) {
  .table-scroll{
    height: 87vh;
  }
}
@media (min-height:850px) {
  .table-scroll{
    height: 88vh;
  }
}


// filter css---------------=================
.hidden-checkbox {
  height: 10px !important;
  width: 16px;
height: 16px !important;
flex-shrink: 0;
border-radius: 4px;
border: 1px solid #D9D9D9 !important;
background: #FFF;
}

.multi-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  // overflow-x: scroll;
  min-height: 20px;
  padding: 4px 0 4px 16px;
  border-bottom: 2px dashed #E5E5E5;
}

.multi-tags span {
  font-size: 12px;
}
.placeholder-text{
  padding: 6px;
  color: #AEAEAE;
}

.multi-options {
  // margin-right: 5px;
  padding: 6px;
  border-radius: 3px;
  display: inline-block;
  font-size: 12px;
  border-radius: 2px;
  background: rgba(40, 0, 252, 0.10);
  color: #2800FC;
}

.multi-search {
  border-radius: 4px;
  outline: none;
  padding-left: 16px !important;
}
.multi-search::placeholder {
 color: #6D7885 !important;
}

.multi-checkbox {
  display: flex !important;
  gap: 12px;
  cursor: pointer;
  align-items: center;
  font-size: 12px;
  color: #3E4754;
font-family: Roboto;
font-style: normal;
font-weight: 400;
line-height: normal;
}


.tooltip-button {

  background: #2800FC;
  color: white;
  border: none;
  padding: 3px 6px;
  font-size: 12px;
  border-radius: 4px;
  cursor: pointer;
}

.more-options {
  display: none;
  flex-wrap: wrap;
  gap: 4px;
}






.all-checkboxes {
  max-height: 120px;
  overflow-y: auto;
  margin-top: 5px;
  padding: 8px 0 4px 16px;
}

.otp-close {
  margin-left: 5px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0) !important;
  color: #2800FC;
}

.search-container {
  position: relative;
  width: 100%;
}

.multi-search {
  width: 100%;
  padding: 10px 35px 10px 10px; /* Space for the icon */
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}

.search-container svg {
  position: absolute;
  right: 10px; /* Adjust icon position */
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
// filter togle and smooth transistion----------------------------------------------------------------

// filter close open animation------
.collapse-transition {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
  opacity: 0;
}

.collapse-transition.open {
  max-height: 500px; /* Adjust based on content size */
  opacity: 1;
}

.collapse-transition.closed {
  max-height: 0;
  opacity: 0;
}
// filter close open animation------END

.multi-selector{
  padding: 0rem 24px 1rem 24px;           /* Typical card body padding */
 
}
.toggle-header {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 10px;
  // background-color: #f8f9fa;
  // border-bottom: 1px solid #ddd;
}

.toggle-header i {
  transition: transform 0.3s ease-in-out;
}
.tablenorecord{
  text-align: center;
  margin: 20px 0;
 
}
.tooltip-content{
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}
.rs-picker-clean{
  display: none;
}

.input::placeholder {
  padding-left: 1px ;  
}
.RsuiteDateRange{
  width: 100%;
}


@media (min-width:1250px) {
  .dialog{
    width: 350px !important;
  }
}
@media (min-height:650px) {
  .filterModal .card-header {
    padding: 16px 24px !important;
  }
}
@media (min-height:700px) {
  .filterModal .card-header {
    padding: 18px 24px !important;
  }
}
@media (min-height:760px) {
  .filterModal .card-header {
    padding: 20px 24px !important;
  }
}
/* Spinner loader */
.loader {


  animation: spin 1s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


// new table====================================


.table-wrapper {
  // width: 100%;
  // overflow-x: auto;
  position: relative;
}

.custom-table {
  // width: 100%;
  border-collapse: collapse;
  // table-layout: fixed; 
}
.custom-table th{
  color: #6D7885;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.custom-table th,
.custom-table td {
  padding: 8px 12px  8px 0;
  text-align: left;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
  font-size: 12px;
}
.custom-table th:last-child,
.custom-table td:last-child {
  padding: 8px 16px 8px 12px;

}
.custom-table td:first-child ,
.custom-table th:first-child {
  padding: 8px 12px 8px 16px;

}

.custom-table th {
  background-color: #F1F5F7;
  font-weight: bold;
}
.sortbtn:hover {
  box-shadow: 0px 0px 1px 1px rgba(108, 108, 108, 0.35);

}
.sortbtn:active {
  transform: scale(0.95); /* Slight shrink effect on click */
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.35);
}
.table-row{
  transition:0.2s linear;
}

.table-row{
  transition:0.3s linear;
}
.table-row:hover {

  background-color: #f9f9f9;
  // box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 1px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.no-data {
  text-align: center;
  padding: 20px;
  font-style: italic;
  color: #888;
}


.custom-table th:last-child{
  background-color: #F1F5F7;
  text-align: end;
}
.table-cell-row .iconButtons{
background-color: #fff;
}
.table-cell-row .iconButtons:hover{
background-color: #fff;
}
.ellipsis{
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis; 
}
// .shadow1,.shadow2 {
//   box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, 
//               rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
//   transition: all 800ms ;
// }
.shadow2 {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, 
              rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: all 800ms ;
}

.shadow1:hover {
  filter: brightness(1.1);
  box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, 
              rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}
.shadow2:hover {
  filter: brightness(1.1);
  box-shadow: #a998ff 3px 3px 6px 0px inset, #aa99ff78 -3px -3px 6px 1px inset;
  background: #5130f9;
}

.ml-12{
  margin-left: 12px;
}
.mr-12{
  margin-right: 12px;
}
.addbtn{
  display: flex;
height: 32px;
padding: 4px 16px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 4px;
background: #2800FC;
}
.addbtn span{
  font-size: 12px;
  color: #fff;
}
// Table widths--------------------------------------------------------
.comn90{
  width: 90px;
}
.comn130,.sourceW,.destinationW{
  width: 130px;
}
.comn140{
  width: 140px;
}
.routeW,.comn150{
  width: 150px;
}
.comn180{
  width: 180px;
}
.comn200{
  width: 200px;
}
@media (min-width:1550px) {
  .comn130{
    width: 145px;
  }
  .comn140{
    width: 160px;
  }
  .comn180{
    width: 190px;
  }
  .comn200{
    width: auto;
  }
}
@media (min-width:1620px) {
  .comn130{
    width: 155px;
  }
  .comn140{
    width: 180px;
  }
  .routeW,.comn150{
    width: 160px;
  }
  .comn180{
    width: 200px;
  }
}
@media (min-width:1750px) {
  .comn130{
    width: 170px;
  }
  .comn140{
    width: 200px;
  }
  .routeW,.comn150{
    width: 180px;
  }
  .comn180{
    width: 210px;
  }
}
@media (min-width:1850px) {
  .comn130{
    width: auto;
  }
  .comn140{
    width: auto;
  }
  .routeW,.comn150{
    width: auto;
  }
  .comn180{
    width: auto;
  }
}
.loading-text {
  font-size: 16px;
}

.dots::after {
  content: "";
  display: inline-block;
  animation: dots-animation 1.5s steps(3, end) infinite;
}

@keyframes dots-animation {
  0% { content: "."; }
  33% { content: ".."; }
  66% { content: "..."; }
  100% { content: "...."; }
}
.rs-input::placeholder {
  text-indent: -15px; /* Moves text to the left */
}


// table open animation=====
// .table-row {
//   position: relative;
//   transform: translateY(10px);
//   opacity: 0;
//   animation: slideFadeIn 0.5s ease-in-out forwards;
// }

// @keyframes slideFadeIn {
//   from {
//     opacity: 0;
//     transform: translateY(10px);
//   }
//   to {
//     opacity: 1;
//     transform: translateY(0);
//   }
// }


/* Sticky Last Column */
// .custom-table th:last-child,
// .custom-table td:last-child {
//   position: sticky;
//   right: 0;
//   background: white;
//   z-index: 9;
//   box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.1);
// }

// Table widths-------------------------END-------------------------------
.fsize-12{
  font-size: 12px;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  background-size: 20px; /* Adjust size */
  width: 24px;
  height: 24px;
  cursor: pointer;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0; /* Hides the default icon */
  position: absolute;
  width: 50% !important;
  margin-left: 25%;
}



