.quickStylesBar {
  margin: 24px;
  // .QuickFiltersButtons{
  Button {
    height: 26px;
    line-height: 0px;
    font-size: 12px;
    margin: 8px;
  }

  // }
  .MuiPickerStaticWrapper-root {
    // .PrivatePickersSlideTransition-root{
    .MuiTypography-caption {
      font-size: 13px;
      font-family: Roboto;
      font-weight: 900;
      font-style: normal;
    }
    Button {
      height: 10pxpx;
      line-height: 5px;
      font-size: 12px;
      margin: 0px;
      font-family: Roboto;
      font-weight: 400;
      font-style: normal;
    }
    // }
  }

  .delayed {
    background-color: #ffe3e1;
    color: #e53d2c !important;
  }
  .untracked {
    background-color: #ffaba2;
    color: #ac1000 !important;
  }
  .reset {
    background-color: #0ab38c;
    color: #ffffff !important;
  }
}
.iconButtons {
  background-color: #f1f5f7;
  border-color: #614bcf !important;
}

//status text
.actionText {
  font-weight: 700;
  font-size: 13px;
}

//action dropdown
.dropbtn {
  color: white;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 16px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
  
}

.dropdown-content {
  display: none;
  position: fixed;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 11;
}
.dropdown .dropdown-con{
  position: absolute;
}
.dropdown-content div {
  color: black;
  padding: 8px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content div:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.action-button {
  cursor: pointer;
  color: #2800fc !important;
}

.action-button-disabled {
  pointer-events: none !important;
  opacity: 0.7;
  color: #2800fc !important;
}

.action-button-cancel {
  cursor: pointer;
  color: #e53d2c !important;
}

//comment dropdown
.comment {
  position: relative;
  display: inline-block;
}

.comment-content {
  display: none;
  position: fixed;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.comment-content div {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.comment-content div:hover {
  background-color: #ddd;
}

.comment:hover .comment-content {
  display: block;
}

// .action-button {
//   cursor: pointer;
//   color: black !important;
// }

//calender styles
.quickStylesBar {
  .MuiPickerStaticWrapper-root {
    position: fixed;
    z-index: 9999;
    direction: ltr !important;
  }
}
.consent-react_selector {
  min-width: 19%;
}
.consentBackground {
  background-color: #f1f5f7;
  padding: 10px;
  border-radius: 6px;
  .consentText {
    font-size: 15px;
    font-weight: 400;
    font-family: "Roboto";
    white-space: nowrap;
  }
  .unTrackedValueDiv {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    cursor: default;
  }
}
.QualityCheckModel {
  .modal-content {
    height: fit-content;
    padding: 20px;
    //  overflow-y: scroll;
  }
}
.QualityCheckCard {
  height: 400px;
  max-height: 420px;
  overflow-y: scroll;
  width: 95%;
  margin-left: 20px;
  background-color: #f1f5f7;
}
.QCImangeClose {
  position: absolute;
  background-color: white;
  width: 20px;
  margin-left: 28px;
  height: 20px;
  border-radius: 11px;
  margin-top: 3px;
  margin-right: 28px;
  .closeIconQc {
    position: relative;
    padding-left: 4px;
    margin-top: -2px;
  }
}
.EpodAck {
  .modal-content {
    // min-width: 900px;
    padding: 25px;
  }
}
.DamagedShortageDetails {
  .modal-content {
    padding: 25px;
  }
}
.EPODImangeClose {
  position: absolute;
  background-color: white;
  width: 20px;
  margin-left: 47px;
  height: 20px;
  border-radius: 11px;
  margin-top: 7px;
  margin-right: 28px;
  .closeIconEPOD {
    position: relative;
    padding-left: 4px;
    margin-top: -2px;
  }
}
.inputBox {
  width: 100%;
  height: 30px;
  border: 1px solid #d4c5c5;
}
@media (max-width: 640px) {
  .mobilePaddingBox {
    padding-top: 120px;
  }
  .pdt10 {
    padding-top: 10px;
  }
}

.tableHeader{
  background: #F1F5F7;
  border-radius: 0;
    margin-bottom: 0;
    padding: 8px 0;
}

.tableHeader .IndexDatatableNeoRow{
  margin-bottom: 0;
 
}
.tableRow{
  border-bottom: 1px solid #E5E5E5;
  border-radius: 0;
    margin-bottom: 0;
    padding: 12px 0;
}

.tableHeader .card-title{
margin: 0;
}

.tableRow .tile-text {
  padding-top: 0;
  height: auto !important;
  margin-bottom: 0;

}

.tableRow .iconButtons{
  background: white;
}
.statusRound{
  padding-left: 25px;
  position: relative;
  color: #3E4754 !important;
    font-weight: 400 !important;
    font-size: 12px !important;
}
.statusRoundSpace{
  // padding-left: 25px;
  display: flex;
  gap: 5px;
}
.hopcode{
  
    font-weight: 400;
    font-size: 12px;
    color: #3E4754;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis; 
    display: inline-block; 
    cursor: pointer;  
}
.hidetext{
  width: 100%; 
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis; 
  display: inline-block; 
  cursor: pointer;  
}
.statusRound::before{
  content: '';
  width: 15px;
  height: 15px;
  position: absolute;
  left: 0;
  border-radius: 20px;
  border: 4px solid;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-self: center;
  border-color: #ff7f70;
  background: red;
}

.loadingprogress-color.statusRound::before{
  background: #4EB4BE;
  border-color: #a2f0fa;
}

.completed-color.statusRound::before{
  background: #2800FC;
  border-color: #ccc9fd;
}
.qualitycompleted-color.statusRound::before{
  background: #28A34C;
  border-color: #a6ecba;
}

.cancelled-color.statusRound::before{
  background: #AC1000;
  border-color: #eecfcc;
}
.planned-color.statusRound::before{
  background: #E19600;
  border-color: #f9eacc;
}
.source-color.statusRound::before{
  background: #006400;
  border-color: #7ccb8d;
}

.shipment-color.statusRound::before{
  background: #A020F0;
  border-color: #ecd2fc;
}
.progress-color.statusRound::before{
  background: #4EB4BE;
  border-color: #ecd2fc;
}
.rejected-color.statusRound::before{
  background: #E53D2C;
  border-color:  #fad8d5;
}
.intransit-color.statusRound::before{
  background: #A020F0;
  border-color: #eeaaf6;
}


/* Filter CSS START*/

.search-main{
  position: relative;
  display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.search-main-view{
  position: absolute;
    top: 0;
    bottom: 0;
    left: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.search-div{
  width: 100%;
}
.search-input-view{
  padding: 11px 42px;
 height: auto;
 margin: 0;
 font-size: 17px;
 box-sizing: border-box;
 border: 1px solid #D9D9D9;
border-radius: 4px;
}

.search-main-view svg path{
stroke: #6D7885;
} 
.search-main .serach-icon, .serach-filter{
  padding: 9px 14px;
  height: auto;
  margin: 0;
  font-size: 12px;
  box-sizing: border-box;
  background: white;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
}
.search-main .serach-icon i, .serach-filter i{ 
  font-size: 17px;
  color: #6D7885;
}
// .filterModal .modal.fade.right:not(.show) .modal-dialog {
//   transform: var(--mdb-modal-fade-right-transform);
// }
// .filterModal .modal .modal-side {
//   position: absolute;
//   width: 100%;
//   right: 0;
//   bottom: 0;
//   margin: 0;
// }
// .filterModal .modal .modal-dialog.modal-top-right{
//   top: 0;
//   right: 0;
// }
.filterModal .modal-content{
  height: 100%;
  border-radius: 0;
}
.filterModal .searchToggleDiv {
  height: auto !important;
  display: block !important;
  align-items: center !important;
  margin-top: 0 !important;
  padding-bottom: 0;
}
.filterModal .modal , .filterModal .modal-dialog{
  top: 0;
}
.filterModal .modal-header p{
  padding: 0;
  margin: 0;
  color: #3E4754;
    font-size: 18px;
    font-weight: 500;
}
.filterModal .modal-header .close {
  color: #6D7885;
  font-size: 30px;
}
.filterModal .modal-header{
  border-bottom: 0;
}
.filterModal .card-body{   
   padding: 0.5rem 1rem;
  }
.filterModal .card{
  background: white;
    border: 0;
    border-top: 1px solid #E5E5E5;
    border-radius: 0;
    box-shadow: unset;
    margin: 0;
    // padding: 8px 0;
}
.filterModal .card-header{   
   background: white;
  border: 0;
  border-radius: 0;
  box-shadow: unset;
  display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 24px;
}
.filterModal .card-header i{    
  font-size: 15px;
  color: #6D7885;
}
.filterModal .card-header span{
  font-size: 12px;
  color: #3E4754;
}
.filterModal input, .filterModal .css-6kakv8-control, .filterModal .css-1lkc6s3-control{
  height: 42px ;
    margin: 0 !important;
    font-size: 12px !important;
    box-sizing: border-box !important;
    border: 1px solid #D9D9D9 ;
    box-shadow: unset !important;
}
.filterModal .css-6kakv8-control .css-gsnqkw-Input input{
  border : 0 !important
}
.filterModal .rs-input-group-md.rs-input-group>.rs-input, .filterModal .rs-input-group-md.rs-input-group>.rs-input-group-addon{
  height: 42px !important;
    margin: 0 !important;
    font-size: 12px !important;
    box-sizing: border-box !important;
    border: 1px solid #D9D9D9 !important;
    box-shadow: unset !important;
    border-radius: 6px;
}
.filterModal .rs-input-group-md.rs-input-group>.rs-input{
  border-right: 0 !important;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.filterModal .rs-input-group-md.rs-input-group>.rs-input-group-addon{
  border-left: 0 !important;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.filterModal .searchToggleDiv .RenderInput{
  width: 100%;
}
.filterModal .searchToggleDiv .select2-container{
  margin: 0;
  // padding: 1rem;
}
.filterModal .searchToggleDiv label{
  display: none;
}
.filterModal .css-1j05i7k-ValueContainer, .filterModal .css-7w6l68-IndicatorsContainer{
  height: 100%;
}
.filterModal  .rs-input-group.rs-input-group-inside, .filterModal .rs-picker:hover, .filterModal .rs-picker:focus{
  height: 100%;
}
.filterModal .dateSearch {
  position: relative;
  top: 0;
}
.filterModal .css-17j17b1-container {
  margin-top: 0;
}
.filterModal .dateSearch .FieldsClass{
  // height: auto !important;
    margin: 0 !important;
    width: 100% !important;
}
.padding-space{
  padding: 21px 21px;
}

.filter-button-view{
  margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2em 1rem;
}
.filter-button-view .apply-filter{
  width: auto;
    height: 32px;
    padding: 4px 16px;
    margin: 0;
    font-size: 12px;
  font-weight: 400;
}
.filter-button-view .apply-reset{
  width: auto;
  height: 32px;
  // padding: 20px 19px;
  padding: 4px 16px;
  margin: 0;
  background: white;
  color: #6D7885 !important;
  border: 1px solid #D9D9D9;
  font-size: 12px;
  font-weight: 400;
}
.filterModal  .css-1paabgf-indicatorContainer svg path{
stroke: #6D7885;
}
.filterModal .css-7w6l68-IndicatorsContainer{margin-right: 8px;}
.search-main .serach-icon i{
  transform: scale(-1, 1);
}
.filterModal .IndexDatatableNeoCol1 .rs-picker{
  border: 0;
}
.filterModal .rs-picker-error>.rs-picker-input-group, .filterModal .rs-picker-error>.rs-picker-input-group:hover{
  outline: unset;
}
.rs-picker-input-group{
  height: 42px !important;
  overflow: hidden;
}
.filterModal .rs-picker:hover, .filterModal .rs-picker:focus{
  height: auto !important;
}
.filterModal .searchToggleDiv .checkBoxList label{
  display: block;
}
.filterModal .searchToggleDiv .checkBoxList .form-check-inline{
  width: 100%;
  gap: 14px;
}

.filterModal .select2-container .css-gsnqkw-Input  input {
  border: 0 !important;
  width: auto !important;
}

/* Modal left right */


.modal.left .modal-dialog,
	.modal.right .modal-dialog {
		position: fixed;
		margin: auto;
		width: 320px;
		height: 100%;
		-webkit-transform: translate3d(0%, 0, 0);
		    -ms-transform: translate3d(0%, 0, 0);
		     -o-transform: translate3d(0%, 0, 0);
		        transform: translate3d(0%, 0, 0);
	}

	.modal.left .modal-content,
	.modal.right .modal-content {
		height: 100%;
		overflow-y: auto;
	}
	
	.filterModal .modal.left .modal-body,
	.filterModal .modal.right .modal-body {
		padding: 0;
	}

  /*Left*/
	.modal.left.fade .modal-dialog{
		left: -320px;
		-webkit-transition: opacity 0.4s linear, left 0.4s ease-out;
		   -moz-transition: opacity 0.4s linear, left 0.4s ease-out;
		     -o-transition: opacity 0.4s linear, left 0.4s ease-out;
		        transition: opacity 0.4s linear, left 0.4s ease-out;
	}
	
	.modal.left.fade.in .modal-dialog{
		left: 0;
	}
        
/*Right*/
	.modal.right.fade .modal-dialog {
		right: -320px;
		-webkit-transition: opacity 0.5s linear, right 0.5s ease-out;
		   -moz-transition: opacity 0.5s linear, right 0.5s ease-out;
		     -o-transition: opacity 0.5s linear, right 0.5s ease-out;
		        transition: opacity 0.5s linear, right 0.5s ease-out;
	}
	
	.modal.right.fade.show .modal-dialog {
		right: 0;
	}
// .trip-table-scroll{
//   overflow-x: scroll;
//   height: 94vh;
// }
.sortbtn {
  cursor: pointer;
  transition: all 0.2s ease-in-out; /* Smooth transition for click effect */
}


