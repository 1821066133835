
.trackingAbosulteRoute {
    position: absolute;
    left: 15px;
    top: 68px;
    background-color: #fff;
    padding: 15px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    width: 400px;
    height: 90%;
    overflow: auto;
}
.mapLeftArrowImg {
    position: absolute;
    top: 68px;
    left: 418px;
    cursor: pointer;
}
.mapRightArrowImg {
    position: absolute;
    top: 68px;
    left: 15px;
    cursor: pointer;
}
.AddressModal {
    min-width: 80% !important;
    min-height: 70% !important;
    height: 70% !important;
}

.addressModalCloseIcon {
    position: absolute;
    top: 4%;
    left: 95%;
    cursor: pointer;
}

.addressCard {

    min-height: 600px !important;
    background: #FFFFFF;
    box-shadow: 0px 3px 13px rgba(0, 0, 0, 0.25) !important;
    border-radius: 4px !important;
}

.labelStyle {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: #05284E;
}

.inputGroup {
    display: flex;
    flex-direction: revert;
    align-content: center;
    justify-content: space-between;
    align-items: center;
}
.buttonStyle{
    background-color: #05284E !important;
    border-radius: 4px !important;
}
.modalContainer{
    justify-content: space-between;
    align-items: center;
    align-content: stretch;

}
.titleClass{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 14px;
    color: #05284E;
}
.subtitleClass{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 14px;
    color: #0C0C0C;
}
.containerDiv{
    display: flex;
    justify-content: space-between;
    background: #F1F5F7;
    border-radius: 4px;
    align-items: center;
    padding : 20px;
    margin-top : 5px;
}
.containerDiv1{
    text-align: center;
    position: relative;
    top: 290px;
}
.sourceImgStyle{
    margin-left: 10px;
    height: 15px;
    position: relative;
    top: -2px;
}
.pac-container{
    z-index: 100000 !important;
}
.aliasInputStyle{
    height: 40px;
    width : 28%;
    border: 0.5px solid #AEAEAE;
    border-radius: 5px;
    background-color: #ffffff;
}
.inputStyle{
    height: 40px;
    width: 80%;
    border: 0.5px solid #AEAEAE;
    border-radius: 5px;
    background-color:#ffffff;
}
.inputGroup{
    .transpoter-react_selector{
        width :80%;
    }
    .react-select__control{
    height: 40px
    }
}
.inputCard{
    width: 63% !important;
}
.inputAliasCard{
    width: 23% !important;
}
.imgCard{
    width: 8% !important;
}
.AddressModalContainer .modal-dialog{
    max-width: 80% !important;
}
input[type="color"] {
	-webkit-appearance: none;
	border: none;
	width: 28px;
	height: 28px;
}
input[type="color"]::-webkit-color-swatch-wrapper {
	padding: 0;
}
input[type="color"]::-webkit-color-swatch {
	border: none;
}