.WaveLoader {
  margin: 0;
  padding: 0;
}

.WaveLoaderdiv {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
}
.ListingPages .WaveLoaderdiv{
    top : 63% !important;
}

.WaveLoaderSpan {
  height: 30px;
  width: 7px;
  margin-right: 10px;
  background-color: #2800fc;
  animation: loading 1s linear infinite;
}
.WaveLoaderSpan:nth-child(1) {
  animation-delay: 0.1s;
}
.WaveLoaderSpan:nth-child(2) {
  animation-delay: 0.2s;
}
.WaveLoaderSpan:nth-child(3) {
  animation-delay: 0.3s;
}
.WaveLoaderSpan:nth-child(4) {
  animation-delay: 0.4s;
}
.WaveLoaderSpan:nth-child(5) {
  animation-delay: 0.5s;
}

@keyframes loading {
  0% {
    height: 0;
  }
  25% {
    height: 25px;
  }
  50% {
    height: 50px;
  }
  100% {
    height: 0;
  }
}
