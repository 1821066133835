.darkBgQuickFilter {
  .quickStylesBar {
    .input-border{
        padding-right: 10px;
    }
  .form-control{
    height: 21px;
    border: none !important;
  }
  .form-control::placeholder{
    padding-left: 0px !important;
  }
  }
}
