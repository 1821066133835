.freight-cost-span-names {
  font-family: Roboto;
  font-style: normal;
  font-size: 18px;
  line-height: 18px;
  font-weight: 700;
  white-space: nowrap;
}
.freight-cost-span-values {
  display: flex;
  font-family: Roboto;
  font-style: normal;
  font-size: 30px;
  line-height: 35px;
  font-weight: 700;
  padding-top: 17px;
}

.invoice-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis !important;
}
.span-invoice-perTrip,
.span-invoice-perShipment,
.freight-cost-name {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  cursor: pointer;
  white-space: nowrap;
}
.freight-cost-name,
.freight-cost-amount {
  padding-top: 3px;
  color: #0C0C0C;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.span-invoice-pd{
  padding-top: 18px;
  padding-left: 30px !important;
  left:5%
}
.freight-cost-name-apx{
  color: #0C0C0C;
}
.freightCostApexchart{
  width: 100%;
}
.costToRevenueRatio-hr-line,
.freightCost-hr-line
{
  margin-top: 15px;
  border-top: 1px solid #B9B9B9;
  width: 97%;
  left:15px;
  position: relative;
}