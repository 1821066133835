#page-topbar-new {
  position: relative;
  height: 43px !important;
  width: 100%;
  left: 0%;
  background-color: #F1F5F7 !important;
}

.navbar-header-new {
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  align-items: center;
  height: 6vh;
  padding: 10px;
  width: 95vw; /*new change */
}

.navbar-header-new-2 {
  display: flex;
  padding: 8px 0px;
  align-items: center;
  /* gap: 16px; */
}

.breadactive {
  color: #2800FC;
}

.breadcrumb-active:hover,
.breadcrumb-active:focus {
  text-decoration: underline;
  cursor: pointer;
}

.profile-name {
  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
  color: #0C0C0C;
  line-height: 14.08px;
  font-style: normal;
}

.profile-chervon {
  width: 10.26px;
  height: 5.13px;
  padding-top: 0px;
  color: #2800FC;
  font-size: 20px;
}

.span-font-roboto {
  font-family: Roboto;
  font-style: normal;
  font-size: 12px;
  font-weight: 400;
}

/* new edits--------------------- */
.toggle-switch-2 {
  background-color: #fff !important;

  /* margin-top: 28px;  */
  display: flex;
  max-width: 220px;
  align-items: center;
  justify-content: center;
  border-radius: 46px;
  background-color: #1b1c31;
  /* position: absolute; */
  top: -17px;
  cursor: pointer;

}

.toggle-switch-2 div {
  color: #3E4754;

  cursor: pointer;
  text-align: center;
  padding: 4px 14px;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
}

.toggle-switch-2 div.isActivated {
  background: #0ab38c;
  border-radius: 26px;
  color: white;
}
.newheader{
  position: sticky;
  top: 0;
  background: #F1F5F7;
  z-index: 10;
}