//
// Widgets.scss
//

// activity widget

.activity-wid {
  margin-top: 8px;
  margin-left: 16px;
  //   border-left: 3px solid green;

  .activity-list {
    position: relative;
    padding: 0 0 40px 30px;
    // margin-left: -3px;

    &:before {
      content: "";
      border-left: 2px dashed rgba($primary, 0.25);
      position: absolute;
      left: 0;
      bottom: 0;
      top: 32px;
    }
    .activity-icon {
      position: absolute;
      left: -15px;
      border-radius: 16px;
      top: 0;
      z-index: 9;
    }

    &:last-child {
      padding-bottom: 0px;
    }
  }
}
