.roboto-font-700{
    font-family: Roboto;
    font-size: 18px;
    font-weight:700;
    font-style:normal 

}
.roboto-font-400{
    font-family: Roboto;
    font-size: 15px;
    font-weight:400;
    font-style:normal 
}
.roboto-font-700-12{
    font-family: Roboto;
    font-size: 12px;
    font-weight:700;
    font-style:normal 

}
.roboto-font-400-12{
    font-family: Roboto;
    font-size: 12px;
    font-weight:400;
    font-style:normal 
}
.roboto-font-400-14{
    font-family: Roboto;
    font-size: 14px;
    font-weight:400;
    font-style:normal 
}
.span-trip-status{
    line-height: 18px;
    color:rgba(5, 40, 78, 1);
    padding-left:10px
}
.span-shipment-name,
.span-indent-name{
    line-height: 18px;
    color:rgba(5, 40, 78, 1);
    padding-left:15px
}
.span-damaged-shipment,
.span-shortage-shipment{
 white-space: initial;
 font-size: 13px;
 font-family: Roboto;
 font-weight: 400;
 font-style: normal;
 flex-wrap: wrap;
}
.shipment-hr-line
{
    margin-top: 15px;
    border-top: 1px solid #B9B9B9;
    width:87%;
    position: relative;
}
.trip-status-hr-line{
    top: 15px;
    margin-top: 15px;
    border-top: 1px solid #B9B9B9;
    width:92%;

}
.TripstatusCardChild{
    height: 280px;
    background-color: #F1F5F7;
    position: relative;
    top: 5%;
}

.operationalTat-hr-line{
    top: 15px;
    margin-top: 15px;
    border-top: 1px solid #B9B9B9;
    width:95%;

}
.carbonFootPrint-hr-line{
    margin-top: 15px;
    border-top: 1px solid #B9B9B9;
    width:97%;
    left:5px
}
.indent-hr-line{
    top: 15px;
    margin-top: 15px;
    border-top: 1px solid #B9B9B9;
    width:94%;
}
.shipment-card{
    width: 100%;
    height: 100px;
    background-color: #F1F5F7 !important;
    /* margin-left: 22px; */
}
.shipment-card-row{
    font-family: Roboto;
    font-size: 13px;
    font-weight: 400;
    padding-left: 10px;
}
.shipment-card-value{
    font-family: Roboto;
    font-weight: 700;
    font-size: 12px;
}
.shortage-shipment-card{
    width: 100%;
    height: 100px;
    background-color: #F1F5F7 !important;
    /* margin-left: 22px; */
}
.shipment-progress{
    border: 1px solid rgba(228, 228, 228,1);
    width: 90%;
}

.span-operationalTat-cirlce1,
.span-tripstatus-circle1{
    top:5px;
    right:0px;
    position: absolute;
    width: 12px;
    height: 12px;
    float: right;
    border-radius: 20px;
}
.span-operationalTat-cirlce2,
.span-tripstatus-circle2{
    position: absolute;
    width: 4px;
    height: 4px;
    float: right;
    border-radius: 20px;
    margin: 2px;
}
.span-operTat-avg-small-circle{
    background-color: #AC1000;
    border: 2px solid #AC1000;
}
.span-operTat-avg-out-circle{
    background-color: #FFD5B5;
    border: 2px solid #FFD5B5;
}
.span-operational-avg{
    width: 100%;
    /* height: 28px; */
    color: #0C0C0C;

}
.span-operTat-aug-small-circle{
    background-color: #FF6F00;
    border: 2px solid #FF6F00;
}
.span-operTat-aug-out-circle{
    background-color: #FFE3E1;
    border: 2px solid #FFE3E1;
}
.span-operTat-qc-small-circle{
    background-color: #28A34C;
    border: 2px solid #28A34C;
}
.span-operTat-qc-out-circle{
    background-color: #A5F2BB;
    border: 2px solid #A5F2BB;
}
.span-operational{
    width: 100%;
    height: 28px;
    color: #0C0C0C;

}
.span-indentAcceptance-name,
.span-indent-rejection-name{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: #0C0C0C;
    height: 15px;
    width: 109px;
}
.span-indentAcceptance-value
{
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    color: #05284E;
    height: 35px;
    
}
.span-Ontimdelivery-value{
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    color:#05284E;
    height: 35px;
}
.span-indent-rejection-value{
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    color: #05284E;
    height: 35px;

}
.card-ontime-dountchart{
    width: 100%;
    height: 148px;
    background-color: rgba(241, 245, 247, 1) !important;
}
.card-indent-dountchart{
    width: 100%;
    height: 150px;
    background-color: rgba(241, 245, 247, 1) !important;
}
.card-transMode-dountchart{
    width: 100%;
    height: 250px;
    background-color: rgba(241, 245, 247, 1) !important;
}
.card-expiry{
    height: 70px;
    background-color: #FFFFFF !important;
    border: 1px solid #f1f5f7 !important;
    padding-bottom: 20px;
    bottom: 1px;

}
.card-expiry-row{
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
   
}
.card-expiry-row,
.CardRownoWrap {
    white-space: nowrap;
    flex-wrap: nowrap !important;
}
.card-expiry-value,
.card-cancel-value{
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    color: rgba(5, 40, 78, 1);
}
.card-exipry-progress{
    border: 3px solid rgba(228, 228, 228,1);
    width: 90%;
    color:#A020F0;
}
.card-cancelled{
    height: 68px;
    background-color: #FFFFFF!important;
    border: 1px solid #f1f5f7 !important;
   bottom: 20px;

}
.card-cancel-name{
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    color: rgba(12, 12, 12, 1);
    height: 15px;
    width: 57px;
}
.tripstatus-names{
    top: 7px;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: #0C0C0C;
    height: 28px;
    /* width: 44px; */

}
.span-operTat-planned-small-circle{
    background-color: rgba(225, 150, 0, 1);
    border: 2px solid rgba(225, 150, 0, 1);
}
.span-trip-planned-out-circle{
    background-color: rgba(254, 217, 144, 1);
    border: 2px solid rgba(254, 217, 144, 1);
}
.span-operTat-complete-small-circle{
    background-color: rgba(0, 100, 0, 1);
    border: 2px solid rgba(0, 100, 0, 1);
}
.span-trip-complete-out-circle{
    background-color: rgba(129, 212, 129, 1);
    border: 2px solid rgba(129, 212, 129, 1);
}
.span-trip-loading-small-circle{
    background-color: rgba(172, 16, 0, 1);
    border: 2px solid rgba(172, 16, 0, 1);
}
.span-trip-loading-out-circle{
    background-color: rgba(255, 171, 162, 1);
    border: 2px solid rgba(255, 171, 162, 1);
}
.span-trip-arrived-small-circle{
    background-color: rgba(255, 111, 0, 1);
    border: 2px solid rgba(255, 111, 0, 1);
}
.span-trip-arrived-out-circle{
    background-color: rgba(255, 213, 181, 1);
    border: 2px solid rgba(255, 213, 181, 1);
}
.span-trip-qcc-small-circle{
    background-color: rgba(40, 163, 76, 1);
    border: 2px solid rgba(40, 163, 76, 1);
}
.span-trip-qcc-out-circle{
    background-color: rgba(165, 242, 187, 1);
    border: 2px solid rgba(165, 242, 187, 1);
}
.span-trip-intransist-small-circle{
    background-color: rgba(143, 0, 255, 1);
    border: 2px solid rgba(143, 0, 255, 1);
}
.span-trip-intransist-out-circle{
    background-color: rgba(233, 206, 255, 1);
    border: 2px solid rgba(233, 206, 255, 1);
}
.span-trip-shipment-small-circle{
    background-color: rgba(188, 81, 255, 1);
    border: 2px solid rgba(188, 81, 255, 1);
}
.span-trip-shipment-out-circle{
    background-color: rgba(234, 200, 255, 1);
    border: 2px solid rgba(234, 200, 255, 1);
}
.operations-dashboard-btn-operations,
.operations-dashboard-btn-transpoter,
.operations-dashboard-btn-invoice
{
    font-family: Roboto !important;
    font-style: normal;
    font-size: 13px;
    height: 28px;
    width: 100%;
    display:contents;
}
.invoice-overflow{
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    }
.operations-dashboard-btn-reset{
    font-family: Roboto !important;
    font-style: normal;
    background-color: #0AB38C !important;
    color: #FFFFFF !important;
    font-size: 13px;
    height: 40px;
}
.cursor-pointer{
    cursor: pointer;
}
.imgIconSpan{
    position: relative;
    top: -2px;
    padding-left: 5px;
}
.pdt5{
    padding-top: 5px;
}
.mgt10{
    margin-top: 10px;
}
.pd18{
    padding: 0px 18px;
}
.mgt5{
    margin-top: 10px;
}
.IndentDetailsCardDiv{
    margin-bottom: 33px;
    margin-left: 6px;
}
.carbonParent{
    background-color:"#F1F5F7"
}
@media (max-width : 1280px) {
    .customDashboardIcon{
        position: relative !important;
        right: 10px !important;
    }
    
}
@media ( min-width:1000px)  and ( max-width: 1200px) {
    .fontRoboto {
        font-size: 18px;
    }
}