.OrderDateRange {
  .rs-picker-toggle-textbox {
    padding-left: 0px;
    display: none;
  }
  .rs-input-group {
    height: 30px;
  }
  .rs-picker-toggle-wrapper:hover:active {
    border: none !important;
    padding: 0px !important;
  }
  .rs-input-group:not(.rs-input-group-inside)
    .rs-picker-date:first-child
    .rs-picker-toggle {
    height: 26px;
  }
  .rs-input-group:not(.rs-input-group-inside)
    .rs-picker-date:last-child
    .rs-picker-toggle {
    margin-right: -39px;
    height: 26px;
  }
  .rs-picker-toggle-placeholder {
    position: absolute;
    top: 4px;
    color: #0c0c0c !important;
  }
  .rs-picker-toggle-value {
    padding-top: 0px;
    padding-right: 5px;
  }
}

.OrderDateRange .rs-picker-toggle-wrapper {
  width: 151px !important;
}
.OrderDateRange .rs-stack-item {
  margin-top: 3px;
}
.OrderDateRange.orderDateIcon {
  position: absolute;
  top: 3px !important;
  right: 6px !important;
}
.OrderDateRange.rs-picker-toggle-value {
  padding-top: 0px;
}
.OrderDateRange .orderDateContainer {
  display: flex;
  gap: 9px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.OrderReleaseCol {
  color: black;
  font-weight: 500;
}
.orderCard {
  box-shadow: 0px 3px 13px rgba(0, 0, 0, 0.25) !important;
  border-radius: 4px !important;
}
.orderStageDate {
  color: #0c0c0c;
}
.invoiceList .IndexDataTableNeoParentScroll {
  overflow-x: scroll;
  width: 96%;
}
.CustomerModal,
.productModal,
.locationModal {
  .Submit {
    background-color: rgb(12, 12, 45);
    color: #fff;
    padding: 10px 30px;
    font-size: 20px;
  }
  .modal-content {
    padding: 40px;
  }
  .modal-title {
    width: 100%;
  }
  .Heading {
    display: flex;
    justify-content: space-between;
    // margin: 0px 20px;
    svg {
      cursor: pointer;
    }
  }
  .modal-body {
    display: flex;
    justify-content: center;
    .imageDiv {
      position: relative;
      width: 100%;
      height: 300px;
      background: #f5f5f5;
      border: 0.5px dashed #c6c6c6;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        border: 1px solid #decdcd;
        border-radius: 5px;
      }
      svg {
        width: 100%;
        cursor: pointer;
      }
      .clickText {
        display: flex;
        justify-content: center;
        font-size: 20px;
      }
    }
  }
}

.CreateTripModal,
.addZoneToState {
  .modal-content {
    padding: 10px;
  }
  .modal-title {
    width: 100%;
  }
  .Heading {
    display: flex;
    justify-content: space-between;
    // margin: 0px 20px;
    svg {
      cursor: pointer;
    }
  }
  .imageDiv {
    position: relative;
    width: 100%;
    height: 300px;
    background: #f5f5f5;
    border: 0.5px dashed #c6c6c6;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      border: 1px solid #decdcd;
      border-radius: 5px;
    }
    svg {
      width: 100%;
      cursor: pointer;
    }
    .clickText {
      display: flex;
      justify-content: center;
      font-size: 20px;
    }
  }
}

.QuickParent {
  display: flex;
  justify-content: flex-end;
  .uploadButton {
    background-color: rgb(5, 5, 35);
    color: #fff;
    padding: 15px 20px;
    font-size: 15px;
    cursor: pointer;
  }
}

/*column container*/
.column-container {
  padding: 0px;
  overflow: auto;
}
.column-container .shipTo {
  color: #000000;
  margin-left: 25px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
}
.text {
  font-size: 18px;
  font-weight: 800;
}
/*padding for each column*/
.pad {
  padding: 5px;
}
.padBill {
  padding: 9px;
}
/*Equal columns*/
.color1 {
  background-color: #f1f5f7;
}
///for 43% width
.col-1-1 {
  float: left;
  width: 43%;
}
.col-1-3 {
  float: left;
  width: 33.23%;
} /*1/3 column*/
.col-1-4 {
  float: left;
  width: 25%;
} /*1/4 column*/

.col-1-6 {
  float: left;
  width: 16.66%;
}
.col1-5 {
  float: left;
  width: 18.5%;
}
/*unequal columns*/
.col-full {
  width: 100%;
} /*full column*/
.col-2-3 {
  float: left;
  width: 66.66%;
} /*2/3 column*/
.col-1-2 {
  float: left;
  width: 48.5%;
  margin-top: 4px;
} /*1/2 column*/

/*Accordion*/
.accordion {
  width: 100%;
  // max-width: 1000px;
  // margin: 2rem auto;
}
.accordion-item {
  background-color: #fff;
  color: #111;
  margin: 1rem 0;
  border-radius: 0.5rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
}
.accordion-item-header {
  padding: 0.5rem 3rem 0.5rem 1rem;
  min-height: 3.5rem;
  line-height: 1.25rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.accordionIcon {
  float: right;
  font-size: 0rem;
  position: absolute;
  right: 0rem;
  background-color: aliceblue;
}

.accordion-item-body-content {
  padding: 1rem;
  line-height: 1.5rem;
  border-top: 1px solid;
  background-color: #f1f5f7;
  border-image: linear-gradient(to right, transparent, #34495e, transparent) 1;
}

.orderStageStyle {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 15px;
  margin-left: 22px;
  color: #24243c;
}
.orderTrackTimeDiff {
  padding: 21px;
  margin: 71px 0px 32px 0px;
  justify-content: center;
  .orderLeadTimeStyle {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    color: #24243c;
    display: grid;
    margin: 0px 0px 39px 60px;
  }
  .leadTimeDiffHeight {
    height: 112px;
  }
  .leadtimedifftext {
    color: #2800fc !important;
  }
  .dotImageTransformLast {
    margin-left: 84px;
    margin-top: 0px;
    display: block;
    position: absolute;
  }
  .img-responsive {
    max-width: 100%;
    height: auto;
  }
}

.leadTimeCol {
  justify-content: center;
  display: grid;
  align-items: end;
  margin: -51px 0px 0px 96px;
}

.orderLineTest {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 10px;
  line-height: 12px;
  color: #4c5b66 !important;
  margin-left: 39px;
  padding: 3px;
  display: flex;
  /* identical to box height */
}
.orderLineTest {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 10px;
  line-height: 12px;
  color: #4c5b66 !important;
  margin-left: 39px;
  padding: 3px;
  display: flex;
  /* identical to box height */
}

.orderHistoryuserText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  margin-left: 5%;
  color: #4c5b66;
}
.inctiveStatus {
  color: #9d9d9d !important;
}
.OrderLineHead {
  color: #2800fc;
  font-weight: 400;
  margin-left: 21px;
  margin-top: 6px;
  margin-bottom: 6px;
}
.inctiveStatus {
  color: #9d9d9d !important;
}
.OrderLineHead {
  color: #2800fc;
  font-weight: 400;
  margin-left: 21px;
  margin-top: 6px;
  margin-bottom: 6px;
}
.track-button {
  background: white !important;
  color: #4c5b66 !important;
  border: 0.5px solid #2800fc !important;
  border-radius: 2px !important;
  height: 36px !important;
  margin-left: 63% !important;
  align-items: center !important;
  height: 30px !important;
  padding-top: 3px !important;
}

.trackHeading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700 !important;
  font-size: 15px !important;
  line-height: 18px;
  /* identical to box height */
  color: #05284e !important;
}

.bold-red {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700 !important;
  font-size: 15px !important;
  line-height: 18px;
  color: red;
  margin-left: 122px;
}

.boldDark-red {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600 !important;
  font-size: 13px !important;
  line-height: 15px;
  color: #8b0000;
}

.trackValuetext {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 15px !important;
  line-height: 18px;
  color: #05284e !important;
}
.typeContainer {
  margin-top: 20px;
}
.custom-datepicker {
  height: 28px !important;
}

//Baxter load optimization

.optiInvoiceList .ListingPages .WaveLoaderdiv {
  top: 23% !important;
  // right: 672px !important;
  z-index: 999 !important;
}
.optiInvoiceList .CardDateTable {
  min-height: 109px !important;
}

@media screen and (max-width: 989px) {
  .col1-5 {
    float: none;
    display: block;
    width: 100% !important;
  }
}

/*Responsiveness of the columns*/
@media screen and (max-width: 1203px) {
  [class*="col-"] {
    float: none;
    display: block;
    width: 100%;
  }
  .headerDesc {
    display: none;
  }
}

@media screen and (max-width: 989px) {
  .modal-content {
    padding: 1px !important;
  }
}

.square-checkbox {
  width: 20px;
  height: 20px;
  border: 2px solid #0ab38c;
  border-radius: 4px;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  position: relative;
}

.square-checkbox::before {
  content: "";
  display: block;
  position: absolute;
  top: calc(50% - 7px);
  left: calc(50% - 7px);
  width: 14px;
  height: 14px;
  border-radius: 3px;
  background-color: transparent;
}

.square-checkbox:checked::before {
  background-color: #0ab38c;
  top: calc(50% - 7px);
  left: calc(50% - 7px);
}

.button_height {
  height: 28px;
  line-height: 10px !important;
  background-color: #0ab38c !important;
  color: #ffffff !important;
}
.button_height_InActive {
  height: 28px;
  line-height: 10px !important;
}
.button_height_Active {
  height: 28px;
  line-height: 10px !important;
  background-color: #05284e !important;
}

.tick-cross-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 225px;
}

.tick-cross {
  display: flex;
  width: 148% !important;
  height: 100% !important;
  fill: currentColor;
  margin-left: -33px;
}

.success {
  color: green;
}

.failure {
  color: red;
}
.orderHistoryDateAlignment {
  float: left;
  margin-left: 65px;
}

.dotImageTransform {
  // transform: rotate(270deg) !important;
  //   width: 9%;
  margin-left: 84px;
  margin-top: 0px;
}

.baxHelpStyle {
  width: 90px;
  border-radius: 0.25rem !important;
  height: 28px;
  background: #f2f2f7 !important;
  border: #f2f2f7 solid 1px !important;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25) !important;
  justify-content: center !important;
  display: flex !important;
  align-items: center !important;
  margin-bottom: 0px !important;
  text-align: center;
  font-weight: 700;
  cursor: pointer;
}

.baxHelpStyle:hover {
  background: #e6e6f2 !important;
  border: #e6e6f2 solid 1px !important;
}
.baxtrip-card-div {
  height: 650px;
  margin-bottom: 72px;
  border: 1px solid rgb(212, 212, 212);
}
.buttonContent {
  display: flex;
  align-items: center;
}

.baxHelpSpan {
  margin-right: 0px;
  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
  color: #0c0c0c;
  line-height: 5.08px;
  font-style: normal;
}

.downloadIcon {
  margin-left: 5px;
}

//master upload template
.uploadTemplateButton {
  margin: 0px 367px 0px 0px !important;
  float: right !important;
  background-color: #0c0c2d;
  color: #fff;
  padding: 10px 30px;
  font-size: 20px;
}

@media screen and (max-width: 450px) {
  .viewTripDetailsCard {
    height: 700px !important;
  }
}
