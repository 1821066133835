.CardHeading {
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: #05284e;
}

.ProfileMainCards {
  margin: 12px;
  padding: 12px;
}

.CardSubHeadings {
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: #05284e;
  margin: 16px 0 12px 0;
}

.CardInputFields {
  border-radius: 4px;
  height: 26px;
  background-color: #f1f5f7;
  border: 0;
  color: #0c0c0c;
  font-weight: 400;
  font-size: 12px;
  padding: 12px;
  width: 232px;
}

// .PasswordShowButtons {
//   border-radius: 0 4px 4px 0;
//   height: 25px;
//   background-color: #ffffff;
//   border-left: none;
//   border-width: 0.5px;
//   border-color: #b9b9b9;
//   position: relative;
//   top: -1px;
// }
.PasswordShowButtons {
  border-radius: 0 4px 4px 0;
  height: 38px;
  width: 44px !important;
  background-color: #ffffff;
  border-width: 0.5px;
  border-color: #ffff;
  position: relative;
  top: -45px;
  left: 91%;
}
.CardPasswordFields {
  border-radius: 0px 0px 0px 0px;
  height: 50px;
  background-color: #ffffff;
  color: #0c0c0c;
  font-weight: 400;
  font-size: 12px;
  padding: 12px;
  width: 100%;
  border-width: 0.5px;
  border-color: #b9b9b9;
  // border-right: none;
}

.HopCard {
  width: 100%;
  height: 100px;
  margin-bottom: 8px;
  padding: 16px;
  background-color: #f1f5f7;
  box-shadow: 0px 0px 4px;
  border-radius: 4px;
  flex-wrap: nowrap;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ol {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    flex-direction: column;
    column-count: 3;
    li {
      list-style-type:none;
    }
}
}

.NotificationSettingsHeaderStyles {
  height: 60px;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  justify-content: center;
  padding: 16px;
  color: #05284e;
  margin-bottom: 4px;
}

.NotificationItemCard {
  background-color: #f1f5f7;
  height: 44px;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  justify-content: center;
  padding: 16px;
  margin: 4px;
  margin-bottom: 1px;
}

.hopDot {
  width: 10px;
}

.hopLabel {
  margin: 0;
}

//toggleSwitch

.toggle-switch {
  position: relative;
  width: 54px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
  &-checkbox {
    display: none;
  }
  &-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 20px;
    margin: 0;
  }
  &-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
    &:before,
    &:after {
      display: block;
      float: left;
      width: 50%;
      height: 24px;
      padding: 0;
      line-height: 24px;
      font-size: 14px;
      color: white;
      font-weight: bold;
      box-sizing: border-box;
    }
    &:before {
      content: "";
      text-transform: uppercase;
      padding-left: 10px;
      background-color: #0ab38c;
      color: #fff;
    }
  }
  &-disabled {
    background-color: #ddd;
    cursor: not-allowed;
    &:before {
      background-color: #ddd;
      cursor: not-allowed;
    }
  }
  &-inner:after {
    content: "";
    text-transform: uppercase;
    padding-right: 10px;
    background-color: #bbb;
    color: #fff;
    text-align: right;
  }
  &-switch {
    display: block;
    width: 20px;
    margin: 3px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 26px;
    border: 0 solid #bbb;
    border-radius: 8px;
    transition: all 0.3s ease-in 0s;
  }
  &-checkbox:checked + &-label {
    .toggle-switch-inner {
      margin-left: 0;
    }
    .toggle-switch-switch {
      right: 0px;
    }
  }
}
@media (min-width: 1300px) and (max-width: 1690px) {
  .PasswordShowButtons{
    left:89%
  }
}
@media (min-width: 1200px) and (max-width: 1299px) {
  .PasswordShowButtons{
    left:88%
  }
  .HopCard {
    ol{
      column-count: 2;
    }
  }
}
@media (min-width: 1000px) and (max-width: 1199px) {
  .PasswordShowButtons{
    left:86%
  }
  .HopCard {
    ol{
      column-count: 2;
    }
  }
}
@media  (max-width: 999px) {
  .PasswordShowButtons{
    left:84%
  }
  .HopCard {
    ol{
      column-count: 2;
    }
  }
}
@media  (max-width: 768px) {
  .PasswordShowButtons{
    left:91%
  }
}
@media (max-width:640px) {
  .HopCard {
    height: 100%;
    ol{
      column-count: 2;
    }
  }
}
@media (max-width:480px) {
  .HopCard {
    ol{
      column-count: 1;
    }
  }
}
